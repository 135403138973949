import React, { useState, useMemo, useEffect } from "react";
import * as XLSX from "xlsx";
import "./App.css";

const API_URL = 'https://www.knowforge.com';

function App() {
  const [statusMessage, setStatusMessage] = useState("");
  const [qaPairs, setQaPairs] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  const [isQuizLoading, setIsQuizLoading] = useState(false); 
  const [progress, setProgress] = useState(0); 
  const [uploadProgress, setUploadProgress] = useState(0);
  const [processingProgress, setProcessingProgress] = useState(0);
  const [currentFile, setCurrentFile] = useState(null);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [processedFiles, setProcessedFiles] = useState(0);
  const [editingIndex, setEditingIndex] = useState(null); 
  const [generatedContent, setGeneratedContent] = useState({});
  const [isGenerating, setIsGenerating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ type: '', content: null });
  const [loadingModal, setLoadingModal] = useState(false);
  const [error, setError] = useState(null);
  const [isPolishing, setIsPolishing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    file: 'all',
    answerLength: 'all',
    questionType: 'all',
    status: 'all',
    dateAdded: 'all',
    hasMemoryAids: 'all'
  });
  const [quizContent, setQuizContent] = useState(null);
  const [quizType, setQuizType] = useState('multiple_choice');
  const [showQuiz, setShowQuiz] = useState(false);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [imageProgress, setImageProgress] = useState(0);
  const [totalSentences, setTotalSentences] = useState(0);
  const [totalPossiblePairs, setTotalPossiblePairs] = useState(0);
  const [status, setStatus] = useState('idle');
  const [sessionId, setSessionId] = useState(null);
  const [extractionProgress, setExtractionProgress] = useState(0);
  const [extractionMessages, setExtractionMessages] = useState([]);
  const [totalPairs, setTotalPairs] = useState(0);
  const [shouldStartStream, setShouldStartStream] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [polishingStates, setPolishingStates] = useState({});

  React.useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && showQuiz) {
        setShowQuiz(false);
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => window.removeEventListener('keydown', handleEscKey);
  }, [showQuiz]);

  useEffect(() => {
    if (sessionId && shouldStartStream) {
      console.log('SessionId updated, starting streaming...');
      console.log('Current session state:', {
        sessionId,
        shouldStartStream,
        isLoading,
        error,
        status
      });
      setTimeout(() => {
        startStreaming();
      }, 0);
      setShouldStartStream(false); 
    }
  }, [sessionId, shouldStartStream]);

  const fileNames = useMemo(() => {
    const names = new Set(qaPairs.map(pair => pair.fileName));
    return ['all', ...Array.from(names)];
  }, [qaPairs]);

  const filteredQAPairs = useMemo(() => {
    return qaPairs.filter(pair => {
      if (searchTerm && !pair.question.toLowerCase().includes(searchTerm.toLowerCase()) &&
          !pair.answer.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }

      if (filters.file !== 'all' && pair.fileName !== filters.file) {
        return false;
      }

      if (filters.answerLength !== 'all') {
        const length = pair.answer.split(' ').length;
        switch (filters.answerLength) {
          case 'short':
            if (length > 30) return false;
            break;
          case 'medium':
            if (length <= 30 || length > 100) return false;
            break;
          case 'long':
            if (length <= 100) return false;
            break;
        }
      }

      if (filters.questionType !== 'all') {
        const questionLower = pair.question.toLowerCase();
        const questionTypes = {
          what: ['what'],
          why: ['why'],
          how: ['how'],
          when: ['when'],
          where: ['where'],
          who: ['who']
        };
        if (!questionTypes[filters.questionType].some(type => questionLower.startsWith(type))) {
          return false;
        }
      }

      if (filters.status !== 'all') {
        if (filters.status === 'enhanced' && !pair.isPolished) return false;
        if (filters.status === 'original' && pair.isPolished) return false;
      }

      if (filters.dateAdded !== 'all') {
        const today = new Date();
        const itemDate = new Date(pair.dateAdded || today);
        switch (filters.dateAdded) {
          case 'today':
            if (itemDate.toDateString() !== today.toDateString()) return false;
            break;
          case 'thisWeek':
            const weekAgo = new Date(today.setDate(today.getDate() - 7));
            if (itemDate < weekAgo) return false;
            break;
          case 'thisMonth':
            const monthAgo = new Date(today.setMonth(today.getMonth() - 1));
            if (itemDate < monthAgo) return false;
            break;
        }
      }

      if (filters.hasMemoryAids !== 'all') {
        switch (filters.hasMemoryAids) {
          case 'with-story':
            if (!pair.story) return false;
            break;
          case 'with-image':
            if (!pair.imageUrl) return false;
            break;
          case 'none':
            if (pair.story || pair.imageUrl) return false;
            break;
        }
      }

      return true;
    });
  }, [qaPairs, searchTerm, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Reset states before starting a new upload
    setIsEnded(false);
    setError(null);
    setQaPairs([]);
    setSessionId(null);
    setExtractionMessages([]);
    setExtractionProgress(0);
    setProcessingProgress(0);
    setIsLoading(true);
    setTotalPairs(0);

    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(`${API_URL}/upload`, {
            method: 'POST',
            body: formData,
        });

        console.log('Upload response status:', response.status);
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';

        while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            buffer += decoder.decode(value, { stream: true });
            const lines = buffer.split('\n');
            buffer = lines.pop();

            for (const line of lines) {
                if (line.startsWith('data: ')) {
                    try {
                        const data = JSON.parse(line.slice(6));
                        console.log('Received SSE data:', data);

                        switch (data.type) {
                            case 'extraction_progress':
                                setExtractionProgress(data.progress);
                                setExtractionMessages(prev => [...prev, data.message]);
                                break;
                            case 'success':
                                console.log('Received success message...');
                                setSessionId(data.sessionId);
                                console.log('Set session ID to:', data.sessionId);
                                setTotalPairs(data.totalPossiblePairs || 0);
                                setShouldStartStream(true);
                                break;
                            case 'error':
                                console.error('Received error:', data.message);
                                setError(data.message);
                                setIsLoading(false);
                                break;
                            default:
                                console.log('Unknown message type:', data.type);
                                break;
                        }
                    } catch (e) {
                        console.error('Error parsing SSE data:', e, line);
                    }
                }
            }
        }

    } catch (error) {
        console.error('Upload error:', error);
        setError(error.message);
        setIsLoading(false);
    }
  };

  const exportToExcel = () => {
    // Create a workbook
    const wb = XLSX.utils.book_new();

    // Create the QA pairs sheet with all pairs
    const qaData = [
      ['Question', 'Answer'], // Headers
      ...qaPairs.map(pair => [pair.question, pair.answer]) // Data rows
    ];
    
    const qaSheet = XLSX.utils.aoa_to_sheet(qaData);
    
    // Set column widths for better readability
    qaSheet['!cols'] = [
      { wch: 50 }, // Question column width
      { wch: 50 }  // Answer column width
    ];
    
    // Add the sheet to the workbook
    XLSX.utils.book_append_sheet(wb, qaSheet, 'Q&A Pairs');

    // Generate timestamp for filename
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-').slice(0, 19);
    
    // Write the file
    XLSX.writeFile(wb, `qa_pairs_${timestamp}.xlsx`);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setModalContent({
      type: 'edit',
      content: qaPairs[index]
    });
    setShowModal(true);
  };

  const handleSaveEdit = (editedQA) => {
    setQaPairs(prevQaPairs => {
      const newPairs = [...prevQaPairs];
      newPairs[editingIndex] = {
        ...newPairs[editingIndex],
        ...editedQA
      };
      return newPairs;
    });
    setShowModal(false);
    setEditingIndex(null);
  };

  const handleDelete = (index) => {
    setQaPairs((prevQaPairs) => prevQaPairs.filter((_, i) => i !== index));
  };

  const handleChange = (e, field, index) => {
    const value = e.target.value;
    setQaPairs((prevQaPairs) => {
      const updatedQaPairs = [...prevQaPairs];
      updatedQaPairs[index] = { ...updatedQaPairs[index], [field]: value };
      return updatedQaPairs;
    });
  };

  const generateStory = async (question, answer, index) => {
    setShowModal(true);
    setLoadingModal(true);
    setModalContent({ type: 'story', content: 'Generating creative story...' });
    
    try {
        const response = await fetch(`${API_URL}/generate-story`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ question, answer })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        if (data.success && data.story) {
            setGeneratedContent(prev => ({
                ...prev,
                [index]: { ...prev[index], story: data.story }
            }));
            setModalContent({ type: 'story', content: data.story });
        } else {
            throw new Error(data.message || 'Failed to generate story');
        }
    } catch (error) {
        console.error('Error generating story:', error);
        setModalContent({ 
            type: 'error', 
            content: `Error: ${error.message || 'Failed to connect to server. Please check if the server is running.'}`
        });
    } finally {
        setLoadingModal(false);
    }
  };

  const generateImage = async (question, answer, index) => {
    setIsGenerating(true);
    setImageProgress(0);
    setShowModal(true);
    setModalContent({
      type: 'image',
      content: null
    });

    try {
      // Start progress simulation
      const progressInterval = setInterval(() => {
        setImageProgress(prev => {
          if (prev < 90) return prev + Math.random() * 15;
          return prev;
        });
      }, 500);

      // Create a descriptive prompt from the Q&A pair
      const prompt = `Create an educational illustration for the following concept: 
        Question: ${question}
        Answer: ${answer}
        Make the image clear, memorable, and suitable for learning purposes.`;

      const response = await fetch(`${API_URL}/generate-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          prompt 
        }),
      });

      const data = await response.json();

      // Clear interval and set to 100% when done
      clearInterval(progressInterval);
      setImageProgress(100);

      // Check if we got a successful response with an image URL
      if (data.success && data.imageUrl) {
        const imageContent = <img src={data.imageUrl} alt="Memory aid visualization" />;
        setModalContent({
          type: 'image',
          content: imageContent
        });
        
        setGeneratedContent(prev => ({
          ...prev,
          [index]: { ...prev[index], imageUrl: data.imageUrl }
        }));
      } else {
        throw new Error(data.error || 'Failed to generate image');
      }

    } catch (error) {
      console.error('Error generating image:', error);
      setModalContent({
        type: 'error',
        content: 'Error generating image. Please try again.'
      });
      setStatusMessage('Error generating image');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleResponse = async (response) => {
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'An error occurred');
    }
    
    if (!response.body) {
      throw new Error('No response body available');
    }

    // Rest of the streaming logic...
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null); // Reset error state
    
    try {
      const response = await fetch('/api/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ /* your request data */ }),
      });

      await handleResponse(response);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    }
  };

  // Add this function to test CORS
  const testUpload = async () => {
    try {
      const response = await fetch(`${API_URL}/test-upload`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      const data = await response.json();
      console.log('Test response:', data);
    } catch (error) {
      console.error('Test error:', error);
    }
  };

  const polishAnswer = async (question, answer, index) => {
    setPolishingStates(prev => ({ ...prev, [index]: true }));
    try {
      const response = await fetch(`${API_URL}/polish-answer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ question, answer })
      });

      console.log('Response received:', response.status); // Debug log

      // Try to parse the response as JSON
      let data;
      try {
        data = await response.json();
        console.log('Response data:', data); // Debug log
      } catch (e) {
        console.error('JSON parse error:', e);
        throw new Error('Failed to parse response');
      }

      if (!response.ok) {
        throw new Error(data?.message || `Server error: ${response.status}`);
      }

      if (!data.success || !data.polished_answer) {
        throw new Error('Invalid response format');
      }

      // Update the QA pairs with the polished answer
      setQaPairs(prevQaPairs => {
        const updatedQaPairs = [...prevQaPairs];
        updatedQaPairs[index] = {
          ...updatedQaPairs[index],
          answer: data.polished_answer,
          isPolished: true
        };
        return updatedQaPairs;
      });

    } catch (error) {
      console.error('Error polishing answer:', error);
      setStatusMessage(`Error polishing answer: ${error.message}`);
    } finally {
      console.log('Clearing loading state for index:', index); // Debug log
      setPolishingStates(prev => ({ ...prev, [index]: false }));
    }
  };

  // Add progress bar component
  const ProgressBar = ({ progress }) => {
    // Ensure progress is a number between 0 and 100
    const normalizedProgress = Math.min(100, Math.max(0, Math.round(progress)));
    return (
      <div className="progress-bar-container">
        <div className="progress-text">{normalizedProgress}%</div>
        <div className="progress-bar-wrapper">
          <div 
            className="progress-bar-fill" 
            style={{ width: `${normalizedProgress}%` }}
          />
        </div>
      </div>
    );
  };

  const generateQuizContent = async (type, pair, index) => {
    setIsQuizLoading(true);
    setIsGenerating(true);
    setProgress(0);
    try {
      console.log('Generating quiz for:', { type, pair });
      
      // Check if we have valid input
      if (!pair || !pair.question || !pair.answer) {
        throw new Error('Invalid Q&A pair provided');
      }

      setProgress(20); // Started processing

      const requestBody = {
        qaPairs: [{
          question: pair.question,
          answer: pair.answer
        }],
        type: type
      };
      console.log('Sending request to server:', requestBody);

      setProgress(40); // Sending request

      const response = await fetch(`${API_URL}/generate-quiz`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(requestBody)
      });

      setProgress(60); // Got response

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server error response:', errorText);
        
        // If server fails, generate a basic quiz from the Q&A pair
        const basicQuiz = generateBasicQuiz(pair, type);
        console.log('Generated basic quiz:', basicQuiz);
        setQuizContent(basicQuiz);
        setQuizType(type);
        setShowQuiz(true);
        setCurrentQuizIndex(0);
        setSelectedAnswer(null);
        setShowAnswer(false);
        setProgress(100);
        return;
      }

      setProgress(80); // Processing data

      const data = await response.json();
      console.log('Raw quiz data from server:', data);

      // Process and set quiz content
      if (!data || !data.questions || data.questions.length === 0) {
        console.log('Server returned empty data, using basic quiz');
        const basicQuiz = generateBasicQuiz(pair, type);
        setQuizContent(basicQuiz);
      } else {
        if (type === 'multiple_choice') {
          data.questions = data.questions.map((q, idx) => {
            const normalizedQuestion = q.question.trim();
            const normalizedOptions = q.options.map(opt => opt.trim());
            const normalizedCorrectAnswer = q.correct_answer.trim();

            return {
              ...q,
              question: normalizedQuestion,
              options: normalizedOptions,
              correct_answer: normalizedCorrectAnswer,
              explanation: q.explanation ? q.explanation.trim() : 'No explanation provided.'
            };
          });
        }
        setQuizContent(data);
      }

      setProgress(100); // Complete
      setQuizType(type);
      setShowQuiz(true);
      setCurrentQuizIndex(0);
      setSelectedAnswer(null);
      setShowAnswer(false);
    } catch (error) {
      console.error('Error generating quiz:', error);
      const basicQuiz = generateBasicQuiz(pair, type);
      setQuizContent(basicQuiz);
      setQuizType(type);
      setShowQuiz(true);
      setCurrentQuizIndex(0);
      setSelectedAnswer(null);
      setShowAnswer(false);
      setProgress(100);
    } finally {
      setIsQuizLoading(false);
      setIsGenerating(false);
    }
  };

  // Helper function to generate a basic quiz from a Q&A pair
  const generateBasicQuiz = (pair, type) => {
    if (type === 'multiple_choice') {
      // Generate basic multiple choice quiz
      const correctAnswer = pair.answer;
      const incorrectOptions = [
        'This is not the correct answer',
        'This is another incorrect option',
        'This is also not correct'
      ];

      return {
        type: 'multiple_choice',
        questions: [{
          question: pair.question,
          options: [...incorrectOptions, correctAnswer],
          correct_answer: correctAnswer,
          explanation: `The correct answer is: ${correctAnswer}`
        }]
      };
    } else {
      // Generate flashcard
      return {
        type: 'flashcard',
        questions: [{
          question: pair.question,
          answer: pair.answer
        }]
      };
    }
  };

  const handleAnswerSelect = (answer) => {
    console.log('Selected answer:', answer);
    setSelectedAnswer(answer);
  };

  const nextQuestion = () => {
    if (currentQuizIndex < quizContent[quizType === 'multiple_choice' ? 'questions' : 'flashcards'].length - 1) {
      setCurrentQuizIndex(prev => prev + 1);
      setSelectedAnswer(null);
      setShowAnswer(false);
    }
  };

  const prevQuestion = () => {
    if (currentQuizIndex > 0) {
      setCurrentQuizIndex(prev => prev - 1);
      setSelectedAnswer(null);
      setShowAnswer(false);
    }
  };

  const isCorrectAnswer = (selected, correct) => {
    if (!selected || !correct) {
      console.log('Missing answer:', { selected, correct });
      return false;
    }
    
    // Remove the letter label and normalize
    const normalizeText = (text) => {
      return text
        .replace(/^[A-D]\)\s*/, '') // Remove letter label
        .trim()
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .replace(/[.,!?;:]/g, '');
    };

    const selectedNorm = normalizeText(selected);
    const correctNorm = normalizeText(correct);
    
    console.log('Comparing answers:', {
      original: { selected, correct },
      normalized: { selected: selectedNorm, correct: correctNorm }
    });
    
    return selectedNorm === correctNorm;
  };

  const renderQuiz = () => {
    // Add checks for quiz content
    if (!quizContent || !quizContent.questions || !quizContent.questions[0]) {
      return <div>No quiz content available</div>;
    }

    const currentQuestion = quizContent.questions[0];

    if (quizContent.type === 'flashcard') {
      return (
        <div className="quiz-question-container">
          <div className="quiz-question">
            {!showAnswer ? currentQuestion.question : currentQuestion.answer}
          </div>
          <div className="quiz-navigation">
            <button 
              className="quiz-nav-btn"
              onClick={() => setShowAnswer(!showAnswer)}
            >
              {showAnswer ? 'Show Question' : 'Show Answer'}
            </button>
          </div>
        </div>
      );
    }

    // Multiple choice quiz
    return (
      <div className="quiz-question-container">
        <div className="quiz-question">
          {currentQuestion.question}
        </div>
        <div className="quiz-options">
          {currentQuestion.options.map((option, idx) => (
            <button
              key={idx}
              className={`quiz-option ${selectedAnswer === option ? 'selected' : ''} 
                ${showAnswer ? (option === currentQuestion.correct_answer ? 'correct' : 'incorrect') : ''}`}
              onClick={() => setSelectedAnswer(option)}
              disabled={showAnswer}
            >
              {option}
            </button>
          ))}
        </div>
        {!showAnswer && (
          <div className="quiz-navigation">
            <button 
              className="quiz-nav-btn"
              onClick={() => setShowAnswer(true)}
              disabled={!selectedAnswer}
            >
              Check Answer
            </button>
          </div>
        )}
      </div>
    );
  };

  const renderFilters = () => (
    <div className="filters-section">
      <div className="search-box">
        <input
          type="text"
          placeholder="Search questions and answers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      
      <div className="filter-controls">


        {/* Answer Length Filter */}
        <select 
          value={filters.answerLength}
          onChange={(e) => handleFilterChange('answerLength', e.target.value)}
          className="filter-select"
        >
          <option value="all">All Lengths</option>
          <option value="short">Short (≤30 words)</option>
          <option value="medium">Medium (31-100 words)</option>
          <option value="long">Long (&gt;100 words)</option>
        </select>

        {/* Question Type Filter */}
        <select 
          value={filters.questionType}
          onChange={(e) => handleFilterChange('questionType', e.target.value)}
          className="filter-select"
        >
          <option value="all">All Question Types</option>
          <option value="what">What Questions</option>
          <option value="why">Why Questions</option>
          <option value="how">How Questions</option>
          <option value="when">When Questions</option>
          <option value="where">Where Questions</option>
          <option value="who">Who Questions</option>
        </select>

        {/* Enhanced Status Filter */}
        <select 
          value={filters.status}
          onChange={(e) => handleFilterChange('status', e.target.value)}
          className="filter-select"
        >
          <option value="all">All Status</option>
          <option value="enhanced">Enhanced Only</option>
          <option value="original">Original Only</option>
        </select>

        {/* Date Added Filter */}
        <select 
          value={filters.dateAdded}
          onChange={(e) => handleFilterChange('dateAdded', e.target.value)}
          className="filter-select"
        >
          <option value="all">All Time</option>
          <option value="today">Today</option>
          <option value="thisWeek">This Week</option>
          <option value="thisMonth">This Month</option>
        </select>

        {/* Memory Aids Filter */}
        <select 
          value={filters.hasMemoryAids}
          onChange={(e) => handleFilterChange('hasMemoryAids', e.target.value)}
          className="filter-select"
        >
          <option value="all">All Memory Aids</option>
          <option value="with-story">With Story</option>
          <option value="with-image">With Image</option>
          <option value="none">No Memory Aids</option>
        </select>
      </div>

      <div className="active-filters">
        {Object.entries(filters).map(([key, value]) => 
          value !== 'all' && (
            <span key={key} className="filter-tag">
              {key}: {value}
              <button 
                onClick={() => handleFilterChange(key, 'all')}
                className="remove-filter"
              >
                ×
              </button>
            </span>
          )
        )}
      </div>
    </div>
  );

  // Update the loading overlay message based on the action
  const LoadingOverlay = ({ message, currentProgress }) => (
    <div className="loading-overlay">
      <div className="loading-content">
        <ProgressBar progress={currentProgress} />
        <p>{message}</p>
      </div>
    </div>
  );

  // Add this function near your other utility functions
  const getMessageType = (message) => {
    if (message.includes('exceed') || message.includes('error') || message.includes('Error')) {
      return 'error';
    }
    if (message.includes('warning') || message.includes('Warning')) {
      return 'warning';
    }
    if (message.includes('success') || message.includes('completed') || message.includes('Completed')) {
      return 'success';
    }
    return 'info';
  };

  const startStreaming = async () => {
    try {
        console.log('Starting streaming...');
        console.log('Current session ID:', sessionId);

        if (!sessionId) {
            console.error('No session ID available');
            setError('Session ID not found');
            return;
        }

        console.log('Creating EventSource...');
        const url = `${API_URL}/stream?session_id=${sessionId}`;
        console.log('EventSource URL:', url);
        
        // Create EventSource without credentials for local development
        const eventSource = new EventSource(url);

        const handleStreamData = (data) => {
            switch (data.type) {
                case 'connected':
                    console.log('Connection established');
                    break;
                case 'qa':
                    console.log('Received QA pair:', data);
                    if (!isEnded) {
                        setQaPairs(prev => {
                            const newPairs = [...prev, {
                                question: data.question,
                                answer: data.answer,
                                fileName: data.fileName
                            }];
                            console.log('Updated QA pairs:', newPairs);
                            setProcessingProgress(data.progress);
                            return newPairs;
                        });
                    }
                    break;
                case 'end':
                    if (!isEnded) {
                        setIsEnded(true);
                        console.log('Stream ended');
                        setIsLoading(false);
                        setProcessingProgress(100);
                        setStatus('Complete');
                        eventSource.close();
                    }
                    break;
                case 'error':
                    console.error('Stream error received:', data.message);
                    setError(data.message);
                    setIsLoading(false);
                    setProcessingProgress(0);
                    setStatus('Error');
                    eventSource.close();
                    break;
                default:
                    console.log('Unknown message type:', data.type);
                    break;
            }
        };

        eventSource.onopen = () => {
            console.log('EventSource opened');
            console.log('EventSource readyState:', eventSource.readyState);
        };

        eventSource.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log('Received data:', data);
                handleStreamData(data);
            } catch (e) {
                console.error('Error parsing event data:', e);
            }
        };

        eventSource.onerror = () => {
            if (eventSource.readyState === EventSource.CLOSED) {
                console.log('Stream closed gracefully.');
            } else {
                console.log('Stream connection interrupted or failed.');
                setError(null);
            }
            eventSource.close();
            setIsLoading(false);
        };

        return () => {
            console.log('Cleaning up EventSource');
            eventSource.close();
        };

    } catch (error) {
        console.error('Error setting up EventSource:', error);
        setError(error.message);
        setIsLoading(false);
    }
};


  return (
    <div className="upload-container">
      <h1>Document Q&A Generator</h1>

      {/* Feature Overview */}
      <div className="features-grid">
        <div className="feature-card">
          <div className="feature-icon">📚</div>
          <h3>Multiple Formats</h3>
          <p>Support for PDF, DOC, DOCX, PPT, PPTX, TXT, and more</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">🤖</div>
          <h3>AI-Powered</h3>
          <p>Advanced AI models for generating relevant questions and answers</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">✨</div>
          <h3>Enhanced Answers</h3>
          <p>One-click answer enhancement with GPT technology</p>
        </div>
        <div className="feature-card">
          <div className="feature-icon">💡</div>
          <h3>Memory Aids</h3>
          <p>Generate stories and images to help remember concepts</p>
        </div>
      </div>

      {/* Usage Instructions */}
      <div className="instructions-section">
        <h2>How it works</h2>
        <div className="steps-grid">
          <div className="step-card">
            <div className="step-number">1</div>
            <h4>Upload Document</h4>
            <p>Select your document in any supported format</p>
          </div>
          <div className="step-card">
            <div className="step-number">2</div>
            <h4>Processing</h4>
            <p>AI analyzes content and generates Q&A pairs</p>
          </div>
          <div className="step-card">
            <div className="step-number">3</div>
            <h4>Enhance</h4>
            <p>Optionally enhance answers or generate memory aids</p>
          </div>
          <div className="step-card">
            <div className="step-number">4</div>
            <h4>Export</h4>
            <p>Download your Q&A pairs in Excel format</p>
          </div>
        </div>
      </div>

      <div className="upload-section">
        <div className="drag-drop-zone">
          <p className="drag-drop-text">
            Drag and drop your files here or click to select files
          </p>
          <label htmlFor="file-upload" className="custom-file-upload">
            <span className="upload-icon">📁</span>
            Choose Files
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={handleFileUpload}
            accept=".pdf,.doc,.docx,.ppt,.pptx,.txt,.rtf,.odt,.csv"
            className="file-input"
            multiple
          />
        </div>

        {isLoading && (
          <div className="progress-section">
            {extractionProgress >= 0 && extractionProgress <= 100 && (
              <>
                <div className="extraction-messages">
                  {extractionMessages.map((message, index) => (
                    <div key={index} className="extraction-message">
                      {message}
                    </div>
                  ))}
                </div>
                <ProgressBar progress={extractionProgress} />
              </>
            )}
          </div>
        )}

        {currentFile && (
          <div className="processing-status">
            <div className="processing-info">
              <div className="spinner" />
              <span>Processing: {currentFile.name}</span>
            </div>
            <div className="progress-container">
              <div className="progress-bar">
                <div 
                  className="progress-bar-fill" 
                  style={{ width: `${progress}%` }}
                />
              </div>
              <div className="progress-text">{progress}% Complete</div>
            </div>
          </div>
        )}
      </div>

      {qaPairs.length > 0 && (
        <>
          <div className="export-container">
            <button onClick={exportToExcel} className="export-btn">
              Export to Excel
            </button>
          </div>

          <div className="progress-section">
            <div className="progress-bar-container">
              <div 
                className="progress-bar-fill" 
                style={{ width: `${Math.min(100, Math.round((qaPairs.length / totalPossiblePairs) * 100))}%` }}
              />
            </div>
            <div className="progress-text">
              {qaPairs.length} pairs generated ({Math.min(100, Math.round((qaPairs.length / totalPossiblePairs) * 100))}%)
            </div>
            {isLoading && (
              <div className="progress-indicators">
                <div className="progress-item">
                  <span className="progress-label">Files Processed:</span>
                  <span className="progress-value">{processedFiles} / {totalFiles}</span>
                </div>
                <div className="progress-item">
                  <span className="progress-label">Generation Progress:</span>
                  <span className="progress-value">{progress}%</span>
                </div>
              </div>
            )}
          </div>

          <div className="qa-container">
            <h2>Generated QA Pairs</h2>
            {renderFilters()}
            <div className="qa-stats">
              <p>Showing {filteredQAPairs.length} of {qaPairs.length} pairs</p>
            </div>
            {isQuizLoading && <LoadingOverlay message="Generating quiz questions..." currentProgress={progress} />}
            {isGenerating && <LoadingOverlay message="Generating memory aid image..." currentProgress={imageProgress} />}
            <div className="qa-grid">
              {filteredQAPairs.map((pair, index) => (
                <div key={index} className="qa-pair">
                  {pair.fileName && (
                    <span className="file-label">{pair.fileName}</span>
                  )}
                  <div className="qa-content">
                    <div className="question">{pair.question}</div>
                    <div className="answer">
                      {pair.answer}
                      {pair.isPolished && (
                        <span className="polished-badge">Enhanced</span>
                      )}
                    </div>
                    
                    <div className="memory-aids">
                      <div className="memory-aid-buttons">
                        <button 
                          onClick={() => generateStory(pair.question, pair.answer, index)}
                          className="generate-btn"
                          disabled={isGenerating}
                        >
                          Generate Story
                        </button>
                        <button 
                          onClick={() => generateImage(pair.question, pair.answer, index)}
                          className="generate-btn"
                          disabled={isGenerating}
                        >
                          Generate Image
                        </button>
                        <button
                          onClick={() => polishAnswer(pair.question, pair.answer, index)}
                          className={`polish-btn ${polishingStates[index] ? 'loading' : ''}`}
                          disabled={polishingStates[index] || pair.isPolished}
                        >
                          {pair.isPolished ? 'Enhanced ✓' : (
                            polishingStates[index] ? (
                              <div className="button-content">
                                <span className="spinner"></span>
                                <span>Enhancing...</span>
                              </div>
                            ) : (
                              'Enhance Answer'
                            )
                          )}
                        </button>
                      </div>

                      <div className="study-aid-buttons">
                        <button
                          onClick={() => generateQuizContent('multiple_choice', pair, index)}
                          className="study-btn quiz-btn"
                          disabled={isQuizLoading}
                        >
                          Practice Quiz
                        </button>
                        <button
                          onClick={() => generateQuizContent('flashcard', pair, index)}
                          className="study-btn flashcard-btn"
                          disabled={isQuizLoading}
                        >
                          Flashcard
                        </button>
                      </div>
                      
                      {generatedContent[index]?.story && (
                        <div className="generated-story">
                          <h4>Memory Aid Story:</h4>
                          <p>{generatedContent[index].story}</p>
                        </div>
                      )}
                      {generatedContent[index]?.imageUrl && (
                        <div className="generated-image">
                          <h4>Memory Aid Image:</h4>
                          <div className="image-container">
                            <img 
                              src={generatedContent[index].imageUrl} 
                              alt="Memory aid visualization" 
                              style={{
                                maxWidth: '256px',  // Limit display width
                                width: '100%',      // Make responsive
                                height: 'auto'      // Maintain aspect ratio
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="qa-buttons">
                    <button onClick={() => handleEdit(index)} className="edit-btn">
                      Edit
                    </button>
                    <button onClick={() => handleDelete(index)} className="delete-btn">
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {/* Quiz Modal */}
      {showQuiz && (
        <div className="modal-overlay" onClick={() => setShowQuiz(false)}>
          <div className="modal-content quiz-modal" onClick={e => e.stopPropagation()}>
            <button className="close-modal" onClick={() => setShowQuiz(false)}>×</button>
            {renderQuiz()}
          </div>
        </div>
      )}

      {/* Tips Section */}
      {qaPairs.length > 0 && (
        <div className="tips-section">
          <h3>💡 Pro Tips</h3>
          <ul>
            <li>Use "Enhance Answer" for more detailed explanations</li>
            <li>Generate memory aids to better retain information</li>
            <li>Export to Excel for offline study</li>
            <li>Edit questions and answers to match your needs</li>
          </ul>
        </div>
      )}

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <p className="footer-text">
            Document Q&A Generator - Transform your documents into interactive learning materials
          </p>
          <p className="footer-text">
            Built with AI technology to help you learn better
          </p>
          <p className="footer-text">
            Contact for feedback and suggestions:{' '}
            <a href="mailto:hhuangweijia@gmail.com" className="footer-contact">
              hhuangweijia@gmail.com
            </a>
          </p>
          <div className="footer-social">
            <p className="footer-text">
              © {new Date().getFullYear()} Document Q&A Generator. All rights reserved.
            </p>
          </div>
        </div>
      </footer>

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
              <button 
                className="close-modal-btn" 
                onClick={() => setShowModal(false)}
              >
                ✕
              </button>
            </div>
            <div className="modal-body">
              {isGenerating ? (
                <div className="loading-container">
                  <div className="spinner"></div>
                  <div className="loading-text">
                    Generating your image... {imageProgress}%
                  </div>
                </div>
              ) : (
                modalContent.content
              )}
            </div>
          </div>
        </div>
      )}

      {/* Add error display */}
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
      
      {statusMessage && (
        <div className={`status-message ${getMessageType(statusMessage)}`}>
          {statusMessage}
        </div>
      )}

      {status !== 'idle' && (
        <div className={`status-message ${status.toLowerCase()}`}>
          {status === 'Processing...' && <div className="loading-spinner" />}
          <span>{status}</span>
          {error && <div className="error-message">{error}</div>}
        </div>
      )}
    </div>
  );
}

export default App;
